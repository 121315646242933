import React from 'react';
import PropTypes from 'prop-types';
import parseMarkdown from 'marked';

// import styles from './utils.module.scss';

export const Markdown = props => {
	const { content } = props;

	props = {
		...props,
		...{
			content: undefined
		}
	};

	delete props.content;

	return <HTML content={parseMarkdown(content, props)} />;
};

Markdown.propTypes = {
	content: PropTypes.string.isRequired
};

export const HTML = props => {
	const html = {
		__html: props.content
	};

	props = {
		...props,
		...{
			content: undefined
		}
	};

	delete props.content;

	return <div {...props} dangerouslySetInnerHTML={html} />;
};

HTML.propTypes = {
	content: PropTypes.node.isRequired
};

export const Conditional = ({ condition, wrapper, children, fallbackWrapper }) => {
	return condition
		? wrapper(children)
		: (fallbackWrapper && fallbackWrapper(children)) || children;
};

Conditional.propTypes = {
	condition: PropTypes.any,
	fallbackWrapper: PropTypes.func,
	wrapper: PropTypes.func.isRequired,
	children: PropTypes.element.isRequired
};
