import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import MetaContext from '../contexts/meta';
import LocaleContext from '../contexts/locale';

// import styles from './meta-tags.module.scss';

const Metatags = () => {
    const {
        currentLocale: locale
    } = useContext(LocaleContext);

    const {
        titles,
        description
    } = useContext(MetaContext);

    const title = titles.join(' | ');

    return (
        <Helmet>
            <html lang={locale} />
            <title>{title}</title>
            <meta name='robots' content='index, follow' />
            <meta name='description' content={description} />
            <meta name='og:locale' content={locale} />
            <meta name='og:type' content='website' />
            <meta name='og:title' content={title} />
            <meta name='og:description' content={description} />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={description} />
            <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
            <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
            <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
            <link rel='manifest' href='/site.webmanifest' />
            <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#ff6316' />
            <meta name="msapplication-TileColor" content="#191919" />
            <meta name="theme-color" content="#ffffff" />
        </Helmet>
    );
};

export default Metatags;