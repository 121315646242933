import React, { createContext, useState } from 'react';

import { usePageBySlug, usePageSlugMap } from '../hooks/use-pages';
import { t } from '../utils/i18n';

import useBlockGeneral from '../hooks/use-block-general';

export const MetaProvider = ({ children, slug }) => {
	const {
		currentPage,
		title: pageTitle,
		parent: pageParent,
		description: pageDescription
	} =
		usePageBySlug(slug) ||
		{
			/* 404 */
		};
	const { company_name } = useBlockGeneral();
	const slugMap = usePageSlugMap();

	const notFoundText = t('NOTFOUND_TEXT');
	const companyName = t('COMPANY_NAME');
	const pageText = t('PAGE');

	const stack = [pageTitle];

	const pageNumber = `${pageText} ${currentPage}`;
	stack.push(currentPage > 1 ? pageNumber : '');

	let parent = pageParent;

	while (parent) {
		const page = slugMap[parent];
		stack.push(page.title);
		parent = page.parent;
	}

	stack.push(`${company_name || companyName}`);

	const currentDescription = pageDescription || notFoundText;
	const currentTitles = stack.filter(Boolean);

	const [description, setDescription] = useState(currentDescription);
	const [titles, setTitles] = useState(currentTitles);

	const value = {
		titles,
		setTitles,
		description,
		setDescription
	};

	return <MetaContext.Provider value={value}>{children}</MetaContext.Provider>;
};

const MetaContext = createContext();

export default MetaContext;
