import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { SVG } from './icons';
import useBlockContact from '../hooks/use-block-contact';

import styles from './contact.module.scss';

const Contact = ({ children, withContainer }) => {
	const {
		title,
		text,
		button_text,
		button_link,
		icon: { publicURL: src }
	} = useBlockContact();

	return (
		<div
			className={[styles.wrapper, (withContainer && styles.wrapperWithContainer) || '']
				.filter(Boolean)
				.join(' ')}>
			<div className={styles.container}>
				<div className={styles.left}>
					<h3 className={styles.title}>{title}</h3>
					<div className={styles.text}>{text}</div>
					<Link className={styles.button} to={button_link}>
						{button_text}
					</Link>
				</div>
				<div className={styles.right}>
					<SVG path={src} className={styles.svg} />
				</div>
			</div>
		</div>
	);
};

Contact.defaultProps = {
	withContainer: true
};

Contact.propTypes = {
	withContainer: PropTypes.bool.isRequired
};

export default Contact;
